#signerCertificate {
  position: relative;
}
#doneCertificate {
  color: black;
  position: absolute;
  bottom: 34px;
  right: 15px;
}
.swal2-html-container {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.swal2-html-container table {
  table-layout: fixed;
  width: 100%;
}
